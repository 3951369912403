import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import {MARKET_URL, MEDTIGO_URL, SERVER_URL, WELCOME_URL, SIMULATION_URL} from 'src/settings'

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const UPDATE_DOCTORID = '@account/UPDATE_DOCTORID';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PICTURE = '@account/update-picture';

const STAGING_WELCOME_URL = "https://staging2.medtigo.com/welcome/"

export function login(email, password, isMobile) {
  let connectCheckout
  if(localStorage.getItem("data") === "/redirect/checkout") {
    connectCheckout = true
  }
  
  return async (dispatch) => {
    try {
      dispatch({type: LOGIN_REQUEST});
      let from_courses = false
      if(sessionStorage.getItem('redirectToCourses')){
        from_courses = true
      }
      const {user, coursesToken} = await authService.loginWithEmailAndPassword(email, password, from_courses);
      if(coursesToken){
        sessionStorage.setItem('coursesToken', coursesToken);
      }
      // get coursesToken from the API response
      const appendWPToken = user.wp_token ? '?t='+user.wp_token : '';
      const urlParams = new URLSearchParams(window.location.search);
        const admin_token = urlParams.get('admin_token') || urlParams.get('t') || authService.getAccessToken();
       const token= admin_token? "&t1=" + admin_token:''
        // window.location.href = MEDTIGO_URL + appendWPToken
        // window.location.href= SERVER_URL + appendWPToken

        const url= document.referrer;
        // if (sessionStorage.getItem('redirectToSimulation')) {
        //   window.location.href = SIMULATION_URL + appendWPToken;
        // } 
        // else 

        
          if(url=="https://market.medtigo.com/"){
            window.location.href= MARKET_URL + appendWPToken + token
          }else 
          // if(url=="https://medtigo.com/" || url==""){
          //   window.location.href= MEDTIGO_URL + appendWPToken
          // }else
        if (sessionStorage.getItem('redirectToSimulation')) {
          window.location.href = SIMULATION_URL + appendWPToken;
        }else if (sessionStorage.getItem('redirectToMarket')) {
          window.location.href = MARKET_URL + appendWPToken;
        } else if (sessionStorage.getItem('redirectToContent')) {
          window.location.href = MEDTIGO_URL + appendWPToken;
        }else if(url=="https://medtigo.com/" || url==""){
              window.location.href= MEDTIGO_URL + appendWPToken + token
           }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });

    } catch (error) {
      dispatch({type: LOGIN_FAILURE});
      throw error;
    }
  };
}

export function forgotPassword(email) {
  return async () => {
    try {
      const forgotPassword = await authService.forgotPassword(email);
      return forgotPassword;
    } catch (error) {
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function updateUserData(response) {
  return (dispatch) => dispatch({
    type: UPDATE_DOCTORID,
    payload: {
      response
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    // dispatch({
    //   type: LOGOUT
    // });
  };
}

export function register(values, endpoint, isMobile) {
  return async (dispatch) => {
    try {
      dispatch({type: LOGIN_REQUEST});
      const urlParams = new URLSearchParams(window.location.search);
      const utm_source = urlParams.get('utm_source') || '';
      const utm_medium = urlParams.get('utm_medium') || '';
      const utm_campaign = urlParams.get('utm_campaign') || '';
      const valuesWithUTM = {...values, ...{utm_source, utm_medium, utm_campaign}};

      const user = await authService.registerUser(valuesWithUTM, endpoint);
      const appendWPToken = user.wp_token ? '?t='+user.wp_token : '';

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });

      if(endpoint === 'apple-login'){
        sessionStorage.setItem("apple-login", true)
      }

      if(user){
        authService.syncOnWordpress({user: {
          email: user.email,
          utm_score: user.utm_score,
          utm_medium: user.utm_medium,
          utm_campaign: user.utm_campaign,
          designation_id: user.designation_id,
          specialty: user.specialty,
          program: user.program,
          id: user.id
        }})


        authService.sendWelcomeEmail({user: {email: user.email, id: user.id}})
      }


        // window.location.href = MEDTIGO_URL + appendWPToken
        // setTimeout(() => {
          // window.location.href= STAGING_WELCOME_URL + appendWPToken
        // }, 5000)
        // window.open("https://medtigo.com/" + appendWPToken,'_blank');


    } catch (error) {
      dispatch({type: LOGIN_FAILURE});
      throw error;
    }
  };
}

export function resetPassword(resetToken, newPassword, confirmPassword) {
  return async (dispatch) => {
    try {
      dispatch({type: LOGIN_REQUEST});
      const user = await authService.resetPassword({resetToken, newPassword, confirmPassword});
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateProfile(update) {
  return async (dispatch) => {
    try {
      const user = await authService.updateUserProfile(update);
      dispatch({
        type: UPDATE_PROFILE,
        payload: user
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateProfilePicture(file) {
  return dispatch => {
    dispatch({
      type: UPDATE_PICTURE,
      payload: file
    });
  }
}
